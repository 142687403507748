import { ColumnStats } from 'smg/admin/hyejoo';
import { Session } from 'smg/admin/session';
import axios, { AxiosRequestConfig, AxiosResponse } from 'smg/axios';
import * as models from 'smg/models';
import { MaybePromise } from 'smg/promise';
import * as inbox from './inbox/query';
import * as orderpayments from './orderpayments/query';
import * as orders from './orders/query';
import * as qrpt from './qrpt/query';
import * as quoterequestprojects from './quoterequestprojects/query';
import * as unpaired from './shippingbills/unpaired';

declare interface myWindow extends Window {
    readonly apiRoot: string;
    readonly SidebarWorkerJS: string;
}
declare const window: myWindow;

interface getter {
    get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
}

export interface ItemOptions {
    href: string | string[];
    get: (getter: getter) => MaybePromise<() => Promise<number>>;
    roles?: Set<models.AdminRoleID>;
}

export const AllItems = new Map<string, Item>();

export class Item {
    private get: ItemOptions['get'];
    private roles: ItemOptions['roles'];
    Href: string[];
    private badges: HTMLElement[] = [];
    private badgeTexts: HTMLElement[] = [];

    BadgeVisible: boolean = false;
    private current: number = 0;
    Loaded: boolean = false;
    CurrentMap?: WorkerResponse;

    constructor(opts: ItemOptions) {
        this.get = opts.get;
        this.roles = opts.roles;
        this.Href = typeof opts.href === 'string' ? [opts.href] : opts.href;

        for (const href of this.Href) {
            AllItems.set(href, this);
        }
    }

    SetupElements() {
        for (const href of this.Href) {
            const badgeSelector = `#sidebar a[href="${CSS.escape(href)}"] .badge`;
            this.badges = Array.from(document.querySelectorAll(badgeSelector));
            this.badgeTexts = Array.from(document.querySelectorAll(`${badgeSelector} .badge-text`));
        }
    }

    async Updater(getter: getter = axios): Promise<() => Promise<number>> {
        const update = await this.get(getter);

        return async () => {
            this.current = await update();
            this.Href.forEach(href => this.CurrentMap?.set(href, this.current));

            this.Loaded = true;
            return this.current;
        };
    }

    Set(n: number) {
        this.current = n;
    }

    UpdateBadgeCounts() {
        if (!this.current) {
            this.badges.forEach(el => el.classList.add('d-none'));
            this.BadgeVisible = false;
            return;
        }

        this.badges.forEach(el => el.classList.remove('d-none'));

        const s = this.current < 100 ? String(this.current) : '99+';
        this.badgeTexts.forEach(el => el.textContent = s);

        this.BadgeVisible = true;
    }

    async Register() {
        if (this.roles?.size) {
            const sess = await Session();
            let hasRecommendedRole = false;
            for (const userAdminRoles of sess.user.userAdminRoles ?? []) {
                if (userAdminRoles.adminRoleID && this.roles.has(userAdminRoles.adminRoleID)) {
                    hasRecommendedRole = true;
                    break;
                }
            }

            if (!hasRecommendedRole) {
                return;
            }
        }

        send({
            action: WorkerAction.RegisterSidebarItem,
            href: this.Href[0],
        });
    }
}

export const WorkerAction = {
    SetAxiosDefaults: 1,
    RegisterSidebarItem: 2,
    Get: 3,
    Update: 4,
    Ping: 5,
    Leave: 6,
    Active: 7,
    Inactive: 8,
} as const;
export type WorkerAction = typeof WorkerAction[keyof typeof WorkerAction];

export declare interface WorkerRequest {
    action: WorkerAction;

    href?: string;
    axiosBaseURL?: string;
}

export type WorkerResponse = Map<string, number>;

let worker: SharedWorker | undefined;

function send(req: WorkerRequest) {
    worker?.port.postMessage(req);
}

export function Get() {
    send({ action: WorkerAction.Get });
}

export function StartSharedWorker() {
    if (SharedWorker) {
        worker = new SharedWorker(window.SidebarWorkerJS);

        worker.onerror = err => {
            console.error('shared worker error:', err);
        };

        worker.port.onmessage = e => {
            const res = e.data as WorkerResponse;

            res.forEach((n, href) => {
                const item = AllItems.get(href);
                if (!item) {
                    return;
                }

                item.Set(n);
                item.UpdateBadgeCounts();
            });
        };

        send({
            action: WorkerAction.SetAxiosDefaults,
            axiosBaseURL: window.apiRoot,
        });

        send({ action: WorkerAction.Get });

        window.addEventListener('unload', () => {
            const req: WorkerRequest = {
                action: WorkerAction.Leave,
            };
            worker?.port.postMessage(req);
        });

        setInterval(() => send({ action: WorkerAction.Ping }), 1000);
    }
}

export function Update() {
    send({ action: WorkerAction.Update });
}

export function SetActive() {
    send({ action: WorkerAction.Active });
}

export function SetInactive() {
    send({ action: WorkerAction.Inactive });
}

export const OrdersPending = new Item({
    href: '/orders/pending/',
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': await orders.PendingQuery(),
        };

        return () => getter.get<ColumnStats>('/orders', { params }).then(resp => resp.data.count);
    },
});

export const OrdersUnplaced = new Item({
    href: '/orders/unplaced/',
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': await orders.UnplacedQuery(),
        };

        return () => getter.get<ColumnStats>('/orders', { params }).then(resp => resp.data.count);
    },
});

export const OrdersPendingStockItems = new Item({
    href: ['/orders/pending/?q=Flags%3A"Stock+Items"', '/orders/pending/stock-items/'],
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': await orders.PendingStockItemsQuery(),
        };

        return () => getter.get<ColumnStats>('/orders', { params }).then(resp => resp.data.count);
    },
});

export const UnshippedLate = new Item({
    href: '/orders/unshipped/',
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': await orders.UnshippedQuery(),
        };

        return () => getter.get<ColumnStats>('/orders', { params }).then(resp => resp.data.count);
    },
});

export const OrdersLate = new Item({
    href: '/orders/late/',
    get: async getter => {
        const params = {
            'colStats': 'id',
            // escaping < here to stop my html syntax highlighter from breaking the page
            // eslint-disable-next-line no-useless-escape
            'q': `${await orders.UnshippedQuery()} factoryShipDate:\<=today`,
        };

        return () => getter.get<ColumnStats>('/orders', { params }).then(resp => resp.data.count);
    },
});

export const OrdersUnconfirmed = new Item({
    href: '/orders/unconfirmed/',
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': await orders.UnconfirmedQuery(),
        };

        return () => getter.get<ColumnStats>('/orders', { params }).then(resp => resp.data.count);
    },
    roles: new Set([models.AdminRoleID.ADMIN_ROLES_FACTORY]),
});

export const OrdersBidsNeeded = new Item({
    href: '/orders/bids-needed/',
    get: getter => {
        const params = {
            'colStats': 'id',
            'q': `${orders.BidsNeededQuery()} userOrderBidsRead:no`,
        };

        return () => getter.get<ColumnStats>('/orders', { params }).then(resp => resp.data.count);
    },
});

export const QuoteRequestProjects = new Item({
    href: ['/quotes/', '/quotes/projects/'],
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': `${await quoterequestprojects.DefaultQuery()} Read:no`,
        };

        return () => getter.get<ColumnStats>('/quote-request-projects', { params }).then(resp => resp.data.count);
    },
});

export const Friday = new Item({
    href: '/friday/',
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': `${await qrpt.FridayQuery()} -Status:"Art Done" -Status:"Vector Done" -Status:"Hold"`,
        };

        return () => getter.get<ColumnStats>('/qrpt', { params }).then(resp => resp.data.count);
    },
    roles: new Set([models.AdminRoleID.ADMIN_ROLES_ARTIST]),
});

export const FridayReviewNeeded = new Item({
    href: '/friday/review-needed/',
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': await qrpt.ReviewNeededQuery(),
        };

        return () => getter.get<ColumnStats>('/qrpt', { params }).then(resp => resp.data.count);
    },
});

export const FridayArtDone = new Item({
    href: '/friday/art-done/',
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': await qrpt.ArtDoneQuery(),
        };

        return () => getter.get<ColumnStats>('/qrpt', { params }).then(resp => resp.data.count);
    },
});

declare interface orderEventsUnaddressedCountResp {
    unaddressed: number;
}

export const Inbox = new Item({
    href: '/inbox/',
    get: () => () => axios.get<orderEventsUnaddressedCountResp>('/v2/order-events/unaddressed-count').then(resp => resp.data.unaddressed),
});

export const InboxNeedsApproval = new Item({
    href: '/inbox/needs-approval/',
    get: async getter => {
        const params = {
            'colStats': 'id',
            'q': await inbox.NeedsApprovalQuery(),
        };

        return () => getter.get<ColumnStats>('/order-events', { params }).then(resp => resp.data.count);
    },
});

export const QBODeposited = new Item({
    href: '/orders/payments/qbo-deposited/',
    get: getter => {
        const params = {
            'colStats': 'id',
            'q': orderpayments.QBODepositedQuery(),
        };

        return () => getter.get<ColumnStats>('/order-payments', { params }).then(resp => resp.data.count);
    },
});

export const UnpairedBills = new Item({
    href: '/shipping-bills/unpaired/',
    get: getter => {
        const params = {
            'colStats': 'id',
            'q': unpaired.DefaultQuery(),
        };

        return () => getter.get<ColumnStats>('/shipping-bills', { params }).then(resp => resp.data.count);
    },
});
