import { Session } from 'smg/admin/session';

export async function DefaultQuery() {
    let defaultQuery = '';

    const sess = await Session();
    if (sess.user.salesTeamUsers?.length) {
        defaultQuery = `Team:None,${sess.user.salesTeamUsers.map(salesTeamUser => `"${salesTeamUser.salesTeam?.name ?? ''}"`).join(',')}`;
    }

    if (sess.user.userFactories?.length) {
        defaultQuery = `Factory:${sess.user.userFactories.map(userFactory => `"${userFactory.factory?.name ?? ''}"`).join(',')}`;
    }

    return defaultQuery;
}

export async function PendingQuery() {
    return `${await DefaultQuery()} Active:yes Pending:yes Preorder:no`;
}

export async function UnplacedQuery() {
    return `${await DefaultQuery()} active:yes pending:no orderStatus:order`;
}

export async function PendingStockItemsQuery() {
    return `${await PendingQuery()} Flags:"Stock Items"`;
}

export async function UnshippedQuery() {
    return `${await DefaultQuery()} active:yes shipmentsCount:0 markedAsShipped:no factoryShipDate:>=today-3y pending:no preorder:no factoryKind:factory lateOrderIgnored:no`;
}

export async function UnconfirmedQuery() {
    return `${await DefaultQuery()} active:yes factoryOrNonBid:yes orderStatus:unconfirmed created:>=today-3y`;
}

export async function ConfirmedQuery() {
    return `${await DefaultQuery()} active:yes orderStatus:confirmed`;
}

export function BidsNeededQuery() {
    return 'active:yes bidsNeeded:yes factory:none unconfirmedOrPreorder:yes bidWinPossible:yes';
}

export async function UnpaidQuery() {
    return `${await DefaultQuery()} Active:yes Pending:no Preorder:no balance:>=0.05 total:>0 invoiceNumber:>=200000`;
}